import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import Loading from '../components/Loading';
import AuthLayout from '../layout/AuthLayout';
import DashboardLayout from '../layout/DashboardLayout';
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import PrintLabel from '../pages/Order/TableOrder/PrintLabel';
import ShipmentLabel from '../pages/ShippingManagement/ShipmentLabel';

const Setting = lazy(() => import('../pages/Master/Setting'));
const UserSession = lazy(() => import('../pages/UserSession'));
const Courier = lazy(() => import('../pages/Master/Courier'));
const Voucher = lazy(() => import('../pages/Master/Voucher'));
const Scoreboard = lazy(() => import('../pages/Scoreboard'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ScoreboardDealmaker = lazy(() => import('../pages/ScoreboardDealmaker'));
const ScoreboardTiktok = lazy(() => import('../pages/ScoreboardTiktok'));
const ScoreboardLeague = lazy(() => import('../pages/ScoreboardLeague'));
const ManageMembership = lazy(() => import('../pages/ScoreboardLeague/ManageMembership'));
const BroadcastMessage = lazy(() => import('../pages/BroadcastMessage'));
const Message = lazy(() => import('../pages/Message'));
const Customer = lazy(() => import('../pages/Customer'));
const TaxInvoice = lazy(() => import('../pages/Finance/TaxInvoice'));
const FormLeads = lazy(() => import('../pages/Leads/FormLeads'));
const DashboardLeads = lazy(() => import('../pages/Leads/dashboard'));
const Leads = lazy(() => import('../pages/Leads/index'));
const LeadNonForm = lazy(() => import('../pages/Leads/LeadNonForm'));
const Category = lazy(() => import('../pages/Master/Category'));
const PaymentChannel = lazy(() => import('../pages/Master/PaymentChannel'));
const Product = lazy(() => import('../pages/Master/Product'));
const Design = lazy(() => import('../pages/Master/Product/Design'));
const AddonProduct = lazy(() => import('../pages/Master/Product/Addons'));
const ImportProduct = lazy(() => import('../pages/Master/Product/ImportProduct'));
const Logo = lazy(() => import('../pages/Master/Product/Logo'));
const Material = lazy(() => import('../pages/Master/Product/Material'));
const Fiber = lazy(() => import('../pages/Master/Product/Material/Fiber'));
const Color = lazy(() => import('../pages/Master/Product/Material/Fiber/Color'));
const MaterialVariant = lazy(() => import('../pages/Master/Product/MaterialVariant'));
const FiberVariant = lazy(() => import('../pages/Master/Product/MaterialVariant/FiberVariant'));
const ColorVariant = lazy(() =>
  import('../pages/Master/Product/MaterialVariant/FiberVariant/ColorVariant')
);
const PriceVariant = lazy(() => import('../pages/Master/Product/PriceVariant'));
const Roles = lazy(() => import('../pages/Master/Roles'));
const ManagePermissions = lazy(() => import('../pages/Master/Roles/ManagePermissions'));
const ManageStatus = lazy(() => import('../pages/Master/Roles/ManageStatus'));
const User = lazy(() => import('../pages/Master/User'));
const VehicleClass = lazy(() => import('../pages/Master/VehicleClass'));
const Manufacturer = lazy(() => import('../pages/Master/Manufacturer'));
const VehicleModel = lazy(() => import('../pages/Master/VehicleModel'));
const AddVehicleModel = lazy(() => import('../pages/Master/VehicleModel/Form/AddVehicleModel'));
const EditVehicleModel = lazy(() => import('../pages/Master/VehicleModel/Form/EditVehicleModel'));
const NotFound = lazy(() => import('../pages/NotFound'));
const FormOrder = lazy(() => import('../pages/Order/FormOrder'));
const TableOrder = lazy(() => import('../pages/Order/TableOrder'));
const EditOrder = lazy(() => import('../pages/Order/TableOrder/EditOrder'));
const ProductKnowledge = lazy(() => import('../pages/ProductKnowledge'));
const DetailProductKnowledge = lazy(() =>
  import('../pages/ProductKnowledge/DetailProductKnowledge')
);
const Advertiser = lazy(() => import('../pages/Report/Advertiser'));
const ReportBrand = lazy(() => import('../pages/Report/Brand'));
const BudgetDaily = lazy(() => import('../pages/Report/Budget/BudgetDaily'));
const BudgetRatio = lazy(() => import('../pages/Report/Budget/BudgetRatio'));
const ReportCity = lazy(() => import('../pages/Report/City'));
const ReportDealmaker = lazy(() => import('../pages/Report/Dealmaker'));
const Finance = lazy(() => import('../pages/Report/Finance'));
const BudgetOmzetDaily = lazy(() => import('../pages/Report/Omzet'));
const ReportOrderSource = lazy(() => import('../pages/Report/OrderSource'));
const ReportPlatform = lazy(() => import('../pages/Report/Platform'));
const ReportProduct = lazy(() => import('../pages/Report/Product'));
const ShippingCost = lazy(() => import('../pages/ShippingCost'));
const TableShipping = lazy(() => import('../pages/ShippingManagement/TableShipping'));
const StatusProduction = lazy(() => import('../pages/StatusProduction'));

export const routeList = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/auth/login" />,
    children: [
      {
        subject: 'Menu Dashboard',
        actions: 'read',
        path: '/',
        key: '/',
        label: 'Dashboard',
        element: <Suspense fallback={<Loading />} children={<Dashboard />} />,
        icon: <i className="fa-solid fa-tv bg-transparent" />,
      },
      {
        subject: 'Scoreboard',
        actions: 'read',
        key: '/scoreboard',
        label: 'Scoreboard',
        icon: <i className="fa-solid fa-chart-line bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Scoreboard',
            actions: 'read',
            path: '',
            key: '/scoreboard',
            label: 'All',
            icon: <i className="fa-solid fa-square-poll-vertical bg-transparent" />,
          },
          {
            subject: 'Scoreboard TikTok',
            actions: 'read',
            path: '',
            key: '/scoreboard-tiktok',
            label: 'TikTok',
            icon: <i className="fa-solid fa-brands fa-tiktok bg-transparent" />,
          },
          {
            subject: 'Scoreboard Dealmaker',
            actions: 'read',
            path: '',
            key: '/scoreboard-dealmaker',
            label: 'Dealmaker',
            icon: <i className="fa-solid fa-ranking-star bg-transparent" />,
          },
          {
            subject: 'Scoreboard League',
            actions: 'read',
            path: '',
            key: '/scoreboard-league',
            label: 'Liga',
            icon: <i className="fa-solid fa-trophy bg-transparent" />,
          },
        ],
      },
      {
        subject: 'Menu Report',
        actions: 'read',
        key: 'report',
        label: 'Laporan',
        icon: <i className="fa-solid fa-file-signature bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Report Finance',
            actions: 'read',
            icon: <i className="fa-solid fa-rupiah-sign" />,
            label: 'Keuangan',
            key: '/finance',
            path: 'finance',
            element: <Suspense fallback={<Loading />} children={<Finance />} />,
          },
          {
            subject: 'Report Platform',
            actions: 'read',
            icon: <i className="fa-solid fa-globe" />,
            label: 'Platform',
            key: '/reports/platforms',
            path: 'reports/platforms',
            element: <Suspense fallback={<Loading />} children={<ReportPlatform />} />,
          },
          {
            subject: 'Report Brand',
            actions: 'read',
            icon: <i className="fa-solid fa-car-side" />,
            label: 'Merk Mobil',
            key: '/reports/brands',
            path: 'reports/brands',
            element: <Suspense fallback={<Loading />} children={<ReportBrand />} />,
          },
          {
            subject: 'Report Product',
            actions: 'read',
            icon: <i className="fa-solid fa-swatchbook" />,
            label: 'Warna Produk',
            key: '/reports/products',
            path: 'reports/products',
            element: <Suspense fallback={<Loading />} children={<ReportProduct />} />,
          },
          {
            subject: 'Report Order Source',
            actions: 'read',
            icon: <i className="fa-solid fa-ad" />,
            label: 'Sumber Order',
            key: '/reports/order-sources',
            path: 'reports/order-sources',
            element: <Suspense fallback={<Loading />} children={<ReportOrderSource />} />,
          },
          {
            subject: 'Report City',
            actions: 'read',
            icon: <i className="fa-solid fa-city" />,
            label: 'Kota Terbanyak',
            key: '/reports/cities',
            path: 'reports/cities',
            element: <Suspense fallback={<Loading />} children={<ReportCity />} />,
          },
          {
            subject: 'Report Dealmaker',
            actions: 'read',
            icon: <i className="fa-solid fa-handshake" />,
            label: 'Dealmaker',
            key: '/dealmaker',
            path: 'dealmaker',
            element: <Suspense fallback={<Loading />} children={<ReportDealmaker />} />,
          },
          {
            subject: 'Report Budget Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-hand-holding-dollar" />,
            label: 'Budget Daily',
            key: '/budget-daily',
            path: 'budget-daily',
            element: <Suspense fallback={<Loading />} children={<BudgetDaily />} />,
          },
          {
            subject: 'Report Omzet Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-money-bills" />,
            label: 'Omzet Daily',
            key: '/omzet-daily',
            path: 'omzet-daily',
            element: <Suspense fallback={<Loading />} children={<BudgetOmzetDaily />} />,
          },
          {
            subject: 'Report Budget Ratio',
            actions: 'read',
            icon: <i className="fa-solid fa-percent" />,
            label: 'Budget Ratio',
            key: '/budget-ratio',
            path: 'budget-ratio',
            element: <Suspense fallback={<Loading />} children={<BudgetRatio />} />,
          },
          {
            subject: 'Report Advertiser',
            actions: 'read',
            icon: <i className="fa-solid fa-ad" />,
            label: 'Advertiser',
            key: '/advertiser',
            path: 'advertiser',
            element: <Suspense fallback={<Loading />} children={<Advertiser />} />,
          },
          // {
          //   subject: 'Progress Produksi',
          //   actions: 'export',
          //   icon: <i className="fa-solid fa-truck-ramp-box text-gray-500 w-5 pl-[2.5px]" />,
          //   label: 'Progress Produksi',
          //   key: '/progress',
          //   path: 'progress',
          //   element: <Suspense fallback={<Loading />} children={<Progress />} />,
          // },
        ],
      },
      {
        subject: 'Menu Master',
        actions: 'read',
        label: 'Master',
        key: 'master',
        icon: <i className="fa-solid fa-database bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'User',
            actions: 'read',
            icon: <i className="fa-regular fa-user" />,
            label: 'Pengguna',
            key: '/users',
            path: 'users',
            element: <Suspense fallback={<Loading />} children={<User />} />,
          },
          {
            subject: 'Permission',
            actions: 'manage',
            icon: <i className="fa-solid fa-low-vision" />,
            label: 'Roles',
            key: '/roles',
            path: 'roles',
            element: <Suspense fallback={<Loading />} children={<Roles />} />,
          },
          {
            subject: 'Scoreboard League',
            actions: 'update',
            path: '/league-membership',
            key: '/league-membership',
            label: 'Atur Liga',
            icon: <i className="fa-solid fa-trophy bg-transparent" />,
            element: <Suspense fallback={<Loading />} children={<ManageMembership />} />,
          },
          {
            path: 'roles/:id',
            element: <Suspense fallback={<Loading />} children={<ManagePermissions />} />,
          },
          {
            path: 'role-statuses/:id',
            element: <Suspense fallback={<Loading />} children={<ManageStatus />} />,
          },
          {
            subject: 'Courier',
            actions: 'read',
            icon: <i className="fa-solid fa-truck" />,
            label: 'Jasa Kirim',
            path: 'couriers',
            key: '/couriers',
            element: <Suspense fallback={<Loading />} children={<Courier />} />,
          },
          {
            subject: 'Payment Channel',
            actions: 'read',
            icon: <i className="fa-solid fa-credit-card" />,
            label: 'Channel Pembayaran',
            path: 'payment-channels',
            key: '/payment-channels',
            element: <Suspense fallback={<Loading />} children={<PaymentChannel />} />,
          },
          {
            subject: 'Product',
            actions: 'read',
            icon: <i className="fa-solid fa-boxes-stacked" />,
            label: 'Atur Produk',
            key: 'master-product',
            path: 'master-product',
            element: <Outlet />,
            children: [
              {
                subject: 'Category',
                actions: 'read',
                icon: <i className="fa-regular fa-rectangle-list" />,
                label: 'Kategori',
                path: 'categories',
                key: '/master-product/categories',
                element: <Suspense fallback={<Loading />} children={<Category />} />,
              },
              {
                subject: 'Product',
                actions: 'read',
                icon: <i className="fa-solid fa-truck-ramp-box" />,
                label: 'Produk',
                key: '/master-product/products',
                path: 'products',
                element: <Suspense fallback={<Loading />} children={<Product />} />,
              },
              {
                subject: 'Manufacturer',
                actions: 'read',
                icon: <i className="fa-solid fa-industry" />,
                label: 'Pabrikan',
                key: '/master-product/manufacturers',
                path: 'manufacturers',
                element: <Suspense fallback={<Loading />} children={<Manufacturer />} />,
              },
              {
                path: 'products/add',
                element: <Suspense fallback={<Loading />} children={<ImportProduct />} />,
              },
              {
                path: 'products/:id/materials',
                element: <Suspense fallback={<Loading />} children={<Material />} />,
              },
              {
                path: 'materials/:id/fibers',
                element: <Suspense fallback={<Loading />} children={<Fiber />} />,
              },
              {
                path: 'fibers/:id/colors',
                element: <Suspense fallback={<Loading />} children={<Color />} />,
              },
              {
                path: 'products/:id/material-variants',
                element: <Suspense fallback={<Loading />} children={<MaterialVariant />} />,
              },
              {
                path: 'material-variants/:id/fiber-variants',
                element: <Suspense fallback={<Loading />} children={<FiberVariant />} />,
              },
              {
                path: 'fiber-variants/:id/color-variants',
                element: <Suspense fallback={<Loading />} children={<ColorVariant />} />,
              },
              {
                path: 'products/:id/variants',
                element: <Suspense fallback={<Loading />} children={<PriceVariant />} />,
              },
              {
                path: 'products/:id/logos',
                element: <Suspense fallback={<Loading />} children={<Logo />} />,
              },
              {
                path: 'products/:id/designs',
                element: <Suspense fallback={<Loading />} children={<Design />} />,
              },
              {
                path: 'products/:id/addons',
                element: <Suspense fallback={<Loading />} children={<AddonProduct />} />,
              },
              {
                subject: 'Vehicle Model',
                actions: 'read',
                icon: <i className="fa-solid fa-boxes-packing" />,
                label: 'Tipe / Model',
                key: '/master-product/vehicle/models',
                path: 'vehicle/models',
                element: <Suspense fallback={<Loading />} children={<VehicleModel />} />,
              },
              {
                path: 'vehicle/models/add',
                element: <Suspense fallback={<Loading />} children={<AddVehicleModel />} />,
              },
              {
                path: 'vehicle/models/:id',
                element: <Suspense fallback={<Loading />} children={<EditVehicleModel />} />,
              },
              {
                subject: 'Vehicle Class',
                actions: 'read',
                icon: <i className="fa-solid fa-warehouse" />,
                label: 'Jenis / Class',
                key: '/master-product/vehicle/class',
                path: 'vehicle/class',
                element: <Suspense fallback={<Loading />} children={<VehicleClass />} />,
              },
            ],
          },
        ],
      },
      {
        subject: 'Setting',
        actions: 'manage',
        label: 'System',
        key: 'system',
        icon: <i className="fa-solid fa-server bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Setting',
            actions: 'manage',
            icon: <i className="fa-solid fa-gears" />,
            label: 'Setting',
            key: '/settings',
            path: 'settings',
            element: <Suspense fallback={<Loading />} children={<Setting />} />,
          },
          {
            path: 'broadcast',
            key: '/broadcast',
            element: <Suspense fallback={<Loading />} children={<BroadcastMessage />} />,
            subject: 'Broadcast',
            actions: 'send',
            label: 'Broadcast',
            icon: <i className="fa-solid fa-broadcast-tower bg-transparent" />,
          },
          {
            path: 'sessions',
            key: '/sessions',
            element: <Suspense fallback={<Loading />} children={<UserSession />} />,
            subject: 'Broadcast',
            actions: 'send',
            label: 'Session',
            icon: <i className="fa-solid fa-users bg-transparent" />,
          },
          {
            path: 'message',
            key: '/message',
            element: <Suspense fallback={<Loading />} children={<Message />} />,
            subject: 'Message',
            actions: 'send',
            label: 'Message',
            icon: <i className="fa-solid fa-message bg-transparent" />,
          },
        ],
      },
      {
        subject: 'Menu Finance',
        actions: 'read',
        label: 'Finance',
        key: 'finance',
        icon: <i className="fa-solid fa-rupiah-sign bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Tax Invoice',
            actions: 'read',
            icon: <i className="fa-solid fa-percent" />,
            label: 'Faktur Pajak',
            key: '/finance/tax-invoice',
            path: '/finance/tax-invoice',
            element: <Suspense fallback={<Loading />} children={<TaxInvoice />} />,
          },
        ],
      },
      {
        subject: 'Leads',
        actions: 'read',
        label: 'Leads',
        key: 'leads',
        icon: <i className="fa-solid fa-ad bg-transparent" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-solid fa-chart-line" />,
            label: 'Dashboard',
            key: '/leads/dashboard',
            path: 'leads/dashboard',
            element: <Suspense fallback={<Loading />} children={<DashboardLeads />} />,
          },
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-solid fa-users" />,
            label: 'Leads Form',
            key: '/leads',
            path: 'leads',
            element: <Suspense fallback={<Loading />} children={<Leads />} />,
          },
          {
            path: 'leads/add',
            element: <Suspense fallback={<Loading />} children={<FormLeads />} />,
          },
          {
            subject: 'Leads Non Form',
            actions: 'read',
            icon: <i className="fa-solid fa-comments" />,
            label: 'Leads Non Form',
            key: '/leads-non-form',
            path: 'leads-non-form',
            element: <Suspense fallback={<Loading />} children={<LeadNonForm />} />,
          },
        ],
      },
      {
        subject: 'Voucher',
        actions: 'read',
        path: '/vouchers',
        key: '/vouchers',
        label: 'Voucher Website',
        icon: <i className="fa-solid fa-percent bg-transparent" />,
        element: <Suspense fallback={<Loading />} children={<Voucher />} />,
      },
      {
        subject: 'Shipping Cost',
        actions: 'check',
        path: 'shipping-cost',
        key: '/shipping-cost',
        label: 'Cek Ongkir',
        element: <Suspense fallback={<Loading />} children={<ShippingCost />} />,
        icon: <i className="fa-solid fa-cart-flatbed bg-transparent" />,
      },
      {
        subject: 'Customer',
        actions: 'read',
        path: 'customers',
        key: '/customers',
        label: 'Customers',
        element: <Suspense fallback={<Loading />} children={<Customer />} />,
        icon: <i className="fa-regular fa-id-card bg-transparent" />,
      },

      {
        subject: 'Order',
        actions: 'create',
        path: 'orders',
        key: '/orders',
        label: 'Form Order',
        element: <Suspense fallback={<Loading />} children={<FormOrder />} />,
        icon: <i className="fa-solid fa-laptop-file bg-transparent" />,
      },
      {
        subject: 'Order',
        actions: 'read',
        path: 'table/orders',
        key: '/table/orders',
        label: 'Tabel Order',
        element: <Suspense fallback={<Loading />} children={<TableOrder />} />,
        icon: <i className="fa-regular fa-rectangle-list bg-transparent" />,
      },
      {
        path: 'table/orders/:orderNumber/edit',
        element: <Suspense fallback={<Loading />} children={<EditOrder />} />,
      },
      {
        subject: 'Barcode',
        actions: 'scan',
        path: 'barcode',
        key: '/barcode',
        label: 'Scan Barcode',
        element: <Suspense fallback={<Loading />} children={<StatusProduction />} />,
        icon: <i className="fa-solid fa-camera bg-transparent" />,
      },
      {
        subject: 'Product Knowledge',
        actions: 'read',
        path: 'product-knowledge',
        key: '/product-knowledge',
        label: 'Product Knowledge',
        element: <Suspense fallback={<Loading />} children={<ProductKnowledge />} />,
        icon: <i className="fa-solid fa-globe bg-transparent" />,
      },
      {
        path: 'product-knowledge/:id',
        element: <Suspense fallback={<Loading />} children={<DetailProductKnowledge />} />,
      },
      {
        subject: 'Shipping',
        actions: 'manage',
        path: 'shippings',
        key: '/shippings',
        label: 'Shipping Management',
        element: <Suspense fallback={<Loading />} children={<TableShipping />} />,
        icon: <i className="fa-solid fa-truck bg-transparent" />,
      },
    ],
  },
  {
    path: 'scoreboard',
    element: <Suspense fallback={<Loading />} children={<Scoreboard />} />,
  },
  {
    path: 'scoreboard-dealmaker',
    element: <Suspense fallback={<Loading />} children={<ScoreboardDealmaker />} />,
  },
  {
    subject: 'Scoreboard League',
    actions: 'read',
    path: 'scoreboard-league',
    element: <Suspense fallback={<Loading />} children={<ScoreboardLeague />} />,
  },
  {
    path: 'scoreboard-tiktok',
    element: <Suspense fallback={<Loading />} children={<ScoreboardTiktok />} />,
  },
  {
    path: '/print/label/:id',
    element: isLoggedIn ? <PrintLabel /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: 'print/label/:id',
        element: <Suspense fallback={<Loading />} children={<PrintLabel />} />,
      },
    ],
  },
  {
    path: '/shippings/shipment-label/:order_id',
    element: isLoggedIn ? <ShipmentLabel /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: 'shippings/shipment-label/:order_id',
        element: <Suspense fallback={<Loading />} children={<ShipmentLabel />} />,
      },
    ],
  },
  {
    path: '/auth',
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/" />,
    children: [
      {
        path: 'login',
        element: <Suspense fallback={<Loading />} children={<Login />} />,
      },

      { index: true, element: <Navigate to="/auth/login" /> },
    ],
  },
  {
    path: '/logout',
    element: isLoggedIn ? <Logout /> : <Navigate to="/auth/login" />,
  },
  {
    path: '*', // Rute wildcard untuk menangkap semua rute yang tidak cocok
    element: <NotFound />,
  },
];
